.root{
  flex-direction: row;
  flex-wrap: wrap;
  --columns:1;
  justify-content: flex-start;
  align-items: stretch;
  filter: blur(0px);
  overflow: scroll;
  transition: filter .5s;
}

.root.blur{
  filter: blur(8px);
}
.root>*{
  width:100%;
  flex:1 1 auto;
  aspect-ratio: unset;
}
.img{
  width:100%;
  background-image: url('./../images/finish.jpg');
  
  aspect-ratio:calc(1162 / 749);
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  
}


:global(.landscape) .img{
  aspect-ratio:calc(1162 / 749 * 2)
}

.img{
  aspect-ratio:unset;
  background-image:none;
}
.img video{width: 100%;}

div.value{
  --size:.2;padding:0 0;width:calc(var(--size) * 100%);flex:0 0 auto;
  transition: width 1s;
}
.label{font-size: 80%;font-weight: bold;padding-left:.5rem;margin:0.3rem 0 0.8rem 0}
span.value{display:inline-block;font-size: 120%;font-weight:bold; color:var(--white);background-color: var(--black);padding:1rem;white-space: nowrap;}

.value{background-color: var(--black);}
.value.my{background-color: var(--blue);}

span.value b{font-size: 150%;}
.text{font-size: 80%;padding:0.5rem;white-space: pre-line;}


/*.value.my span{width:100%;text-align: center;}*/

.value.noEmission{
  position: relative;
  background-color: var(--white);
  --size:.8 !important;
  background-image: url('./../images/cloud.png');
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat;
}
.noEmission .value.my{
  background-color: transparent;
  color:var(--blue);
  
  
}
.value.noEmission img{
  position: absolute;
  right: -2em;
  top:50%;
  transform: translateY(-50%);

  width:1.5rem;



}

.loading{
  position: absolute;
  top:-20%;
  left:-20%;
  width: 140%;
  height: 140%;
  background-color: rgba(255,255,255,.95);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.spinner{
  background-image: url('./../images/spinner.svg');
  width:10%;
  height: 10%;
  max-width: 64px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(0deg);
  animation: rotateAni 2s infinite; 
  aspect-ratio: 1;
}

@keyframes rotateAni {
  0%{
    transform: rotate(0deg) ;
  }
  
  100%{
    transform: rotate(360deg);
  }
}

.hidden{
  opacity:0;
  transition: opacity .1s;
  pointer-events: none;
}


.root form{margin:0;padding:0;}