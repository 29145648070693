.root{
  position:absolute;
  top:6rem;
  left:1rem;
  right:1rem;
  bottom:2rem;
  
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap:1rem;

  
}

/* #region dots */

.dots{
  background-color: rgba(0,0,0,0);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap:5px;
  height:3px;
  transition: backgroundColor 1s;
}
.dots>*{
  flex: 1 1 auto;
  background-color: var(--black);
  height: 1px;
  transition: height .5s, backgroundColor 1s;
}
.dots .active{
  height: 100%;
  background-color: var(--blue);
}
.dots.finished{
  background-color:var(--blue);
}
.dots.dots.finished>*{
  background-color: var(--blue);
}

/* #endregion dots */


.header{}
.header h1{
  font-size:1.4rem;
  margin-top:1rem;
  line-height: 1.2;
  min-height: 3.4rem;
}

.content{
  flex:1 1 auto;

}

.footer{
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.footerBtn{
  pointer-events: all;
  opacity: 1;
  height:2rem;
  width:5rem;
  transition: opacity .5s;
  background-color: var(--gray-light);
  border-radius: 1rem;
  padding:.5rem 1rem;
  background-image: url('./../images/arrowRightIcon.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: .7rem;
  cursor: pointer;
}

.footerBtn:hover{
  background-color: var(--gray-dark) !important;
  padding:0;
}
.footerBtn:nth-child(1){
  
  background-image: url('./../images/arrowLeftIcon.svg');

}
.footerBtn.hidden{
  opacity: 0;
 
  pointer-events: none;
} 

/*#region amnimation*/
.animation{
  position:absolute;
  top:0;
  width:100%;
  height: 100%;
  padding-top:10%;
  background-color: var(--gray-light);
  pointer-events: all;
  opacity: 1;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  
  
  
}


/*#endregion amnimation*/