.root{position: relative;  background-color: var(--gray-light);}

.bg{
  pointer-events: none;
  width:100%;
  height:100%;
  background-image: url('./../images/CO2Small.svg');
  background-repeat: no-repeat;
  background-position: center 90%;
  background-size:50%;
}

:global(.landscape) .bg{
  background-position: center 90%;
  background-size:20%;
}

.container{
  --columns:3;
  --gap:5px;

  position: absolute;
  top:0;
  left:0;
  width: 100%;
  max-height:100%;

  display: flex;
  flex-wrap: wrap;
  gap:var(--gap);
  overflow: auto;
  
}

.container>*{
  
  width:calc((100% - (var(--columns) - 1) * var(--gap))/var(--columns));
  aspect-ratio:1/1;
}

