.root{
  --delay:1s;
  --delayStep:.1s;
  position: relative;
  background-color: var(--blue);
  color:var(--white);
  cursor: pointer;
  animation:viewAni 1s var(--delay) forwards;
  opacity: 0;

  
}


.root:nth-child(0){--delay:0s}
.root:nth-child(1){--delay:calc(var(--delayStep) * 1)}
.root:nth-child(2){--delay:calc(var(--delayStep) * 2)}
.root:nth-child(3){--delay:calc(var(--delayStep) * 3)}
.root:nth-child(4){--delay:calc(var(--delayStep) * 4)}
.root:nth-child(5){--delay:calc(var(--delayStep) * 5)}
.root:nth-child(6){--delay:calc(var(--delayStep) * 6)}
.root:nth-child(7){--delay:calc(var(--delayStep) * 7)}
.root:nth-child(8){--delay:calc(var(--delayStep) * 8)}

@keyframes viewAni {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50%{
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/*.root:hover, */.root.active{
  background-color: var(--gray-dark);
}


.root>*{
  pointer-events: none;
  position: absolute;
  left:0;
  text-align: center;
  width: 100%;
}

.top{
  top:15px;
  font-size: 1.6rem;
}
.middle{
  height:100%;
  top:0;
  
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: min(20px, 1.6rem);
  font-size: 1.6rem;
  
  
  
  overflow: hidden;
}

:global(.landscape) .middle{

  font-size: 2.5rem;
  

}

.middle.icon{
  
}
.middle.icon img{
  max-width:50%;  
  max-height:50%;
}


.middle.icon.chio img{
  width:60%;
  max-height: 35%;
  margin-top:15% 
}

.middle.icon .img{
  width:100%;
  aspect-ratio:1;
  max-height: 80%;
  height: unset;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 50%;
  background-color: var(--white);
}


.middle.icon.chio .img{
  /* //TODO */
  mask-size:contain;

}

.middle.icon .img.bus{
  aspect-ratio: 2.9;
} 
.middle.icon .img.car{
  aspect-ratio: 2.5;
} 
.middle.icon .img.car_l{
  aspect-ratio: 2.46;
}
.middle.icon .img.car_m{
  aspect-ratio: 2.68;
}
.middle.icon .img.car_s{
  aspect-ratio: 1.62;
}
.middle.icon .img.chio{
  aspect-ratio: 1.3;
}
.middle.icon .img.feet{
  aspect-ratio: 0.58;
  
  
}

.middle.icon .img.cycle{
  aspect-ratio: 1.63;
} 
.middle.icon .img.flight{
  aspect-ratio: 2.9;
} 
.middle.icon .img.scooter{
  aspect-ratio: 1.125;
} 
.middle.icon .img.train{
  aspect-ratio: 2.57;
} 


.slideshow{
  --pos:0;
  height:100%;
  width:100%;
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items: center;
  
  transform: translateX(calc(var(--pos) * -100%));
  transition: transform .5s;
}
.slideshow>*{
  width:100%;
  height:100%;
  flex:0 0 auto;

}

.middle.icon .slideshow>*{
  top:0;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom{
  bottom:10px;
  font-size: .7rem;
  white-space: pre-line;
}

:global(.landscape) .bottom{

  font-size: 1.5rem;
  bottom:1rem;

}


.bigAnimation{
  --animationDuration:4s;
  width:min(400px, 75vw);
  height:min(400px, 75vw);
  background-color: transparent;
  
  color:var(--blue);
  font-size: 2rem;
  font-weight: lighter;
  animation: bigAniMiddle var(--animationDuration) 0s infinite;
  opacity: 1;
  justify-content: center !important;
}
.bigAnimation .middle{height:unset}
.bigAnimation .middle.icon .img{background-color: var(--blue);}
.bigAnimation .bottom{
  font-size: 2rem;
  
  animation:fadeInBottom 1s calc(var(--animationDuration) * .25) forwards;
  opacity: 0;
}

@keyframes bigAniMiddle {
  0%{
    transform: translateX(-100%);
    opacity: 0;
  }
  30%{
    transform: translateX(0%);
    opacity: 1;
  }
  80%{
    transform: translateX(0%);
    opacity: 1;
  }
  100%{
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes fadeInBottom {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}


.root{
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  gap:0px;
  padding:10px 0;
  align-content: center;

}

.root>*{
  
  position: unset;
  left:unset;
  
  
}

.middle.icon .img{
  mask-size:contain;
  min-width:50%;
  max-width:50%;
  max-height:80%;
  
}



/*

.top{
  top:15px;
  font-size: 1.6rem;
}
.middle{
  font-size: min(20px, 1.6rem);
  font-size: 1.6rem;
  top:50%;
  transform: translateY(-50%);
  overflow: hidden;
}

:global(.landscape) .middle{

  font-size: 2.5rem;
  

}*/