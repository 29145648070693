.root{
  position: absolute;
  
  width:100%;
  height:var(--appHeight);

  top:0;
  left:0;
  z-index:5;
  background-color: var(--blue);
  
}

.root.opened{
  pointer-events: all;
  display: block;
  left:0;
  opacity: 1;
  transition: left .5s, opacity 0s;
}

.root.closed{
  pointer-events: none;
  display:block;
  left:var(--appWidth);
  opacity: 0;
  transition: left .2s, opacity 0.1s .2s;
}

.root.opened{
  left:0;
  top:0;
  transition: top .5s, opacity 0s;
}

.root.closed{
  left:0;
  top:var(--appHeight);
  
  transition: top .2s, opacity 0.1s .2s;
}



.logo{
  position: absolute;
  display: block;
  height: 38px;
  top: 2rem;
  left:50%;
  opacity: 0;
  transform: translateX(-50%);
  
}

.opened .logo{
  opacity: 1;
  transition: opacity .3s .6s;
}

.container{
  color:var(--white);
  position: absolute;
  top:100px;
  left:1rem;
  right:1rem;
  bottom: 1rem;
  border-top:2px solid var(--white);
  overflow-y: auto;
}

.container>*{
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 3;
  border-top:1px solid var(--white);
  
}


.container .ia{
  cursor: pointer;
}

.container>.ia:hover{
  background-color: var(--black);
  padding-left:10px;
  transition: padding-left .3s, background-color .3s;
}


.language{
  display: flex;
  justify-content:space-between;
  align-items: center;

}
.language>span{flex: 1 1 auto;display: inline-block;}
.language>div{display: flex;justify-content:right;gap:20px;font-size:1rem;font-weight: lighter;min-width: 100px;}