.root{
  position: absolute;
  width:100%;
  height: 100%;
  top:0;
  left:0;
  z-index: 4;
  pointer-events: none;
  
}

.menuBtn{
  position: absolute;
  bottom:1.5rem;
  z-index: 5;
  left:50%;
  transform: translateX(-50%);
  pointer-events: all;
}
:global(.intro) .menuBtn{
  display:none
}

.top{
  height:5rem;
  width: 100%;
  display:flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.top>.row{
  flex:1 1 auto;
  height: 53%;
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: var(--white);
  width:100%;
  padding:0 0 .5
  rem 0
}

.top>.row:nth-child(2){
  background-color: var(--gray-light);
  padding:0 3rem;
  height: 47%;
}

:global(.landscape) .top>.row{
  justify-content: center;
  gap:50px;
}

.top>.row span{
  font-size: 110%;
  font-weight: bold;
}

.top>.row img{
  height: 50%;
}

.top>.row img.chio{
  height: 55%;
}
.top>.row img.sap{
  height: 40%;
}

.top>.row img.mercedes{
  height: 60%;
}
.top>.row img.allianz{
  height: 40%;
}
.top>.row img.ta{
  height: 40%;
}
.top>.row img.rolex{
  height: 60%;
}


