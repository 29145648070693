
@import url(fonts/fonts.css);

.root{
  --white:white;
  --black:rgba(22,47,68,1);
  --gray-light:rgba(241,241,241,1);
  --gray-dark:rgba(141,162,186,1);
  --blue:rgba(0,157,223,1);
  --blue1:rgba(0, 156, 223, 0.877);
  --appWidth:100vw;
  --appHeight:100vh;
  height: var(--appHeight);
  width: 100%;

  background-color: var(--white);
  color:var(--black);
  
}
a{
  color:var(--blue)
}
.btn_circle{
  background-color: var(--gray-light);
  color:var(--black);
  border-radius: 50%;
}
.btn_circle.active{
  background-color: var(--black);
  
}
