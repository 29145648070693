.root{
  --animationDuarion:3s;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s;
  height:100%;
  width: 100%;
}

.visible{}
.hidden{
  opacity: 0;
 
  pointer-events: none;}

.startBtn{
  position: absolute;
  z-index: 3;
  background-color: var(--blue);
  color:var(--white);
  padding:0.5rem 1.5rem;
  border-radius: 1rem;
  text-align: center;
  display: inline-block;
  font-size: 1.1rem;
  position: absolute;
  bottom:10%;
  cursor: pointer;
  text-decoration: none;
  opacity: 0;
  animation: btnAnimation var(--animationDuarion) .5s;
  animation-fill-mode: forwards;
}


.root.hidden .startBtn{
  /*display:none*/
}

.bgImg{
  position: absolute;
  width:100%;
  height: 100%;
  top:0;
  left:0;
  background-image: url('./../images/bg_l.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

:global(.portrait) .bgImg{
  background-image: url('./../images/bg_p.jpg');
}

:global(.landscape) .bgImg{
  background-image: url('./../images/bg_l.jpg');
}

.masked{
  animation: maskedAnimation1 var(--animationDuarion);
  animation-fill-mode: forwards;
  mask-image: url('./../images/CO2Great.svg');
  mask-size: 75%;
  mask-position: 50% 57%;
  mask-repeat: no-repeat;

}
:global(.landscape) .masked{
  mask-size:25%;
}

.semi{
  background-color: var(--red);
  
  position: absolute;
  width:100%;
  height: 100%;
  top:0;
  left:0;
  opacity: 0;

  animation: semiAnimation var(--animationDuarion);
  animation-fill-mode: forwards;

}
.semi .bgImg{opacity: .25;}

.co2{
  position: absolute;
  width:100%;
  height: 100%;
  top:0%;
  left:0%;
 
  background-image: url('./../images/CO2Great.svg');
  background-size: 75%;
  background-position: 50% 57%;


  background-repeat: no-repeat;

  animation: co2Animation var(--animationDuarion);
  animation-fill-mode: forwards;
}

:global(.landscape) .co2{
  background-size: 25%;
}

._1 .masked{
  animation: maskedAnimation2 1s;
  animation-fill-mode: forwards;
}
:global(.landscape) ._1 .masked{
  animation-name: maskedAnimation2_l;
}

._1 .semi .bgImg{opacity: 1;transition: opacity 0.3s 0.8s;}
._1 .startBtn{opacity: 0  !important;}

@keyframes maskedAnimation1{
  0% {
    opacity: 0;
   
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  80%{
    opacity: 1;
    mask-position: 50% 67%;
  }
  100% {
    opacity: 1;
    mask-position: 50% 57%;
  }
}


@keyframes maskedAnimation2{
  
  0%{
    mask-size: 75%;
    mask-position: 50% 57%;
  }
  100% {
    mask-size: 2000%;
    mask-position: 46% 57%;
  }
}
@keyframes maskedAnimation2_l{
  
  0%{
    mask-size: 25%;
    mask-position: 50% 57%;
  }
  100% {
    mask-size: 2000%;
    mask-position: 46% 57%;
  }
}

@keyframes semiAnimation {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  50%{
    opacity:0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes btnAnimation {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes co2Animation {
  0% {
    background-position: 50% 67%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50%{
    opacity: 1;
    background-position: 50% 67%;
  }
  70%{
    opacity: 0;
    
  }
  100% {
    opacity: 0;
    background-position: 50% 57%;
  }
}
